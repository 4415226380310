import { Table, Space, Button, Tooltip } from "antd";
import { EditOutlined, LockOutlined, CopyOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { toast } from "react-toastify";

function UserListNew({ result = [], navigateToEditUser, toggleUser }) {
  const navigate = useNavigate();

  const copyUserId = (record) => {
    navigator.clipboard
      .writeText(record._id)
      .then(() => {
        toast.success("User ID copied to clipboard!"); // Show success toast
      })
      .catch(() => {
        toast.error("Failed to copy User ID"); // Show error toast if copying fails
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Relationship Manager",
      dataIndex: "relationship_manager",
      key: "relationship_manager",
      render: (relationship_manager) => relationship_manager?.[0]?.username,
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => dayjs(createdAt).format("YYYY-MM-DD"),
    },
    {
      title: "Alias Account",
      dataIndex: "belongs_to",
      key: "belongs_to",
      render: (belongs_to) => belongs_to ? "Yes" : "No",
    },
    {
      title: "Custom User",
      dataIndex: "custom_user",
      key: "custom_user",
      render: (custom_user) => custom_user ? "Yes" : "No",
    },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit User">
            <Button
              type="primary"
              ghost
              icon={<EditOutlined />}
              onClick={() => navigateToEditUser(record)}
            />
          </Tooltip>
          <Tooltip title={record.active ? "Deactivate User" : "Activate User"}>
            <Button
              danger={record.active}
              onClick={() => toggleUser({ email: record.email })}
            >
              {record.active ? "Deactivate" : "Activate"}
            </Button>
          </Tooltip>
          <Tooltip title="Edit User Subscriptions">
            <Button
              icon={<LockOutlined />}
              onClick={() =>
                navigate(`/home/user-management/report-assignment`, {
                  state: {
                    userEmail: record.email,
                    userId: record._id,
                    userName: record.name,
                  },
                })
              }
            >
              Manage Report Access
            </Button>
          </Tooltip>
          <Tooltip title="Copy user id">
            <Button type="primary" ghost icon={<CopyOutlined />} onClick={() => copyUserId(record)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={result}
      rowKey="_id"
      pagination={{ pageSize: 10 }}
    />
  );
}

export default UserListNew;