import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  InputNumber,
  Select,
  Radio,
  Row,
  Col,
  Spin,
  Tooltip,
} from "antd";
import {
  useCreateNewUserMutation,
  useEditNewUserMutation,
  useGetRelationShipManagerEmailQuery,
} from "../../../redux/api/createUserApi";
import { toast } from "react-toastify";
import _ from "lodash";
import Cookies from "universal-cookie";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useLazyGetHubListQuery } from "../../../redux/api/assignReportApi";

const UserForm = ({ userData, setToSearchScreen }) => {
  const [form] = Form.useForm();
  const cookies = new Cookies();
  const role = cookies.get("ROLE");
  const isPartner = cookies.get("ISPARTNER");
  const [isOnTrial, setIsOnTrial] = useState(false);

  const generatePassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+?";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    // Ensure the password contains at least one uppercase letter, one digit, and one special character
    const hasUppercase = /[A-Z]/.test(password);
    const hasDigit = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+<>?]/.test(password);

    // If any condition is missing, regenerate the password
    if (!hasUppercase || !hasDigit || !hasSpecialChar) {
      return generatePassword();
    }

    form.setFieldsValue({ password });
  };
  const accountTypes = {
    Admin: "owner",
    View: "view only",
    "View and Download": "view & download",
  };

  const UserAccess = [
    {
      label: "All Reports",
      tooltip: "All Public Reports are accessible",
      value: "all_reports",
    },
    {
      label: "Semi Access",
      tooltip:
        "Combination of selected Public and Custom Reports are accessible",
      value: "semi_access",
    },
    {
      label: "Custom Reports",
      tooltip: "Only Custom Reports are accessible",
      value: "custom_access",
    },
  ];

  const {
    data: relationshipManagerEmailData,
    isLoading: relationshipManagerEmailLoading,
    isError: relationshipManagerEmailError,
  } = useGetRelationShipManagerEmailQuery();

  const [getHubList, { data: hubListData }] = useLazyGetHubListQuery();

  useEffect(() => {
    if (role === "SALES_EXECUTIVE") {
      getHubList();
    }
  }, [role]);

  const onFinish = (values) => {
    const newValue = {
      library_credits: {
        total: values.creditsLibraryTotal,
      },
      ondemand_credits: {
        total: values.onDemandTotal,
      },
      company_name: values.company,
      analyst_hours: values.analystHours ?? 0,
      name: values.name,
      email: values.email,
      password: values.password,
      relationship_manager_email: values.relationshipManagerEmail,
      relationship_manager_username: relationshipManagerEmailData?.data.find(
        (item) => item.email_id === values.relationshipManagerEmail
      )?.name,
      account_type: accountTypes[values.accountType],
      platform: values.platform,
      have_myra: values?.have_myra || false,
      myra_credits: values?.myRaCredits || 0,
      allowed_domains: values.allowedDomains
        ? values.allowedDomains.split(",").map((domain) => domain.trim())
        : "@" + values.email.split("@")[1],
      access_type: values.onTrial ? "trial" : "full",
    };

    if (values?.user_access === "semi_access") {
      newValue.semi_access = true;
      newValue.custom_user = false;
    } else if (values?.user_access === "custom_access") {
      newValue.custom_user = true;
      newValue.semi_access = false;
    } else {
      newValue.semi_access = false;
      newValue.custom_user = false;
    }

    const updatedValue = {};
    if (userData) {
      updatedValue.user_id = userData._id;
      updatedValue.update = newValue;
      delete updatedValue.update.password;
      editNewUser(updatedValue).then((res) => {
        if (res.error) {
          toast.error(res.error.data.message ?? "Failed to edit user");
        } else {
          toast.success("User edited successfully");
          form.resetFields();
          setToSearchScreen(true);
        }
      });
      return;
    }
    if (role === "SALES_EXECUTIVE") {
      //Create selected hub list id with name
      const hubList = values.hubList.map((item) => {
        return {
          id: item,
          name: hubListData?.data.find((hub) => hub.id === item).name,
        };
      });
      newValue.hub_list = hubList;
    }
    createNewUser(newValue);
  };

  const [
    createNewUser,
    {
      isLoading: createNewUserLoading,
      error: createNewUserError,
      isSuccess: createNewUserSuccess,
    },
  ] = useCreateNewUserMutation();

  const [editNewUser, { isLoading: editNewUserLoading }] =
    useEditNewUserMutation();

  useEffect(() => {
    if (createNewUserError) {
      toast.error(createNewUserError.data.message ?? "Failed to create user");
    }

    if (createNewUserSuccess) {
      toast.success("User created successfully");
      form.resetFields();
    }
  }, [createNewUserError, createNewUserSuccess]);

  const [rules, setRules] = useState([
    {
      required: true,
      message: "Please input your password!",
    },
    {
      min: 8,
      message: "Password must be at least 8 characters long",
    },
    //Capital letter is mandatory in password
    {
      pattern: new RegExp("^(?=.*[A-Z])"),
      message: "Password must contain at least one capital letter",
    },
  ]);

  useEffect(() => {
    if (userData) {
      //Set Empty array of rules for password field
      setRules([]);

      form.setFieldsValue({
        name: userData?.name,
        email: userData?.email,
        password: userData?.password,
        company: userData?.company_name,
        platform: _.capitalize(userData?.platform),
        // accountType: userData.account_type,
        accountType: _.invert(accountTypes)[userData?.account_type],
        relationshipManagerEmail: userData?.relationship_manager?.[0]?.email,
        analystHours:
          userData?.remaining_analyst_hours ?? userData?.analyst_hours,
        creditsLibraryTotal: userData?.library_credits?.remaining,
        onDemandTotal: userData?.ondemand_credits?.remaining,
        have_myra: userData?.have_myra || false,
        myRaCredits: userData?.myra_credits || 0,
        user_access: userData?.semi_access
          ? "semi_access"
          : userData?.custom_user
          ? "custom_access"
          : "all_reports",
        allowedDomains: userData?.allowed_domains?.join(", "),
        onTrial: userData?.access_type === "trial",
      });

      // Set the trial state based on user data
      setIsOnTrial(userData?.access_type === "trial");
    }
  }, [userData]);

  // Handle the trial checkbox change
  const handleTrialChange = (e) => {
    const isChecked = e.target.checked;
    setIsOnTrial(isChecked);

    // If checked, set account type to "View"
    if (isChecked) {
      form.setFieldsValue({ accountType: "View" });
    }
  };

  const { Option } = Select;

  if (relationshipManagerEmailError) {
    toast.error("Failed to fetch Relationship Manager Email");
  }
  return (
    <>
      <Spin spinning={createNewUserLoading || editNewUserLoading} fullscreen />

      <Form
        style={{
          width: "50%",
          margin: "auto",
          marginTop: "1rem",
          position: "relative",
        }}
        form={form}
        layout="horizontal"
        onFinish={onFinish}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 15 }}
      >
        <Form.Item
          label={<span style={{ fontSize: "0.875rem" }}>Name</span>}
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: "0.875rem" }}>Email</span>}
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
              type: "email",
            },
          ]}
        >
          <Input disabled={userData ? true : false} />
        </Form.Item>

        <Form.Item label="Password" name="password"
          rules={[
            {
              required: userData ? false : true,
              message: "Please input client's password!",
            },
            {
              min: 8,
              message: "Password must be at least 8 characters long",
              required: userData ? false : true,
            }
          ]}
        >
          <Input.Password
            disabled={userData ? true : false}
            style={{ width: "calc(100% - 170px)" }}
          />
        </Form.Item>

        {userData ? null : (
          <Form.Item
            // wrapperCol={{ offset: 7, span: 15 }}
            style={{
              position: "absolute",
              right: "8.3%",
              top: "111px",
              width: "150px",
            }}
          >
            <Button onClick={generatePassword}>Generate Password</Button>
          </Form.Item>
        )}

        <Form.Item
          label={<span style={{ fontSize: "0.875rem" }}>Company Name</span>}
          name="company"
          rules={[{ required: true, message: "Please input your company!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: "0.875rem" }}>Select Platform</span>}
          name="platform"
          rules={[{ required: true, message: "Please check the platform!" }]}
          initialValue="Synapse"
        >
          <Radio.Group options={["Synapse"]} />
        </Form.Item>

        <Form.Item
          label={
            <span style={{ fontSize: "0.875rem" }}>
              On Trial{" "}
              <Tooltip title="Set the user on trial period for 2 weeks from today. After trail is over, user will not be able to access the platform. Account type is set to View only.">
                <InfoCircleOutlined style={{ marginLeft: 5 }} />
              </Tooltip>
            </span>
          }
          name="onTrial"
          valuePropName="checked"
        >
          <Checkbox onChange={handleTrialChange} />
        </Form.Item>

        <Form.Item
          label={<span style={{ fontSize: "0.875rem" }}>Account Type</span>}
          name="accountType"
          rules={[{ required: true, message: "Please select account type!" }]}
        >
          <Radio.Group
            options={
              isPartner === true || isOnTrial
                ? ["View"]
                : ["Admin", "View", "View and Download"]
            }
            disabled={isOnTrial}
          />
        </Form.Item>

        <Form.Item
          label={
            <span style={{ fontSize: "0.875rem" }}>Relationship Manager</span>
          }
          name="relationshipManagerEmail"
          rules={[
            { required: true, message: "Please select Relationship Manager!" },
          ]}
        >
          <Select
            loading={relationshipManagerEmailLoading}
            showSearch
            placeholder="Select a Relation Manager"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {relationshipManagerEmailData?.data.map((item, index) => (
              <Option key={index} value={item.email_id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {role !== "SALES_EXECUTIVE" && (
          <>
            <Form.Item
              label={
                <span
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    style={{
                      color: "#ff4d4f",
                      fontSize: "0.875rem",
                    }}
                  >
                    *
                  </span>{" "}
                  <span style={{ fontSize: "0.875rem" }}>
                    {userData ? "Remaining Analyst Hours " : "Analyst Hours "}
                  </span>
                </span>
              }
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="analystHours"
                    rules={[
                      {
                        required: true,
                        message: `
                    Please input ${userData ? "remaining" : null} Analyst Hours!
                    `,
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      min={0}
                      placeholder={userData ? "Remaining" : "Total"}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {userData && (
                <Form.Item wrapperCol={{ offset: 0, span: 15 }}>
                  {/* Create Label */}
                  <span>Total : {userData?.analyst_hours}</span>
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item
              label={
                <span
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    style={{
                      color: "#ff4d4f",
                      fontSize: "0.875rem",
                    }}
                  >
                    *
                  </span>{" "}
                  <span style={{ fontSize: "0.875rem" }}>{`${
                    userData ? "Remaining Credits" : "Credits"
                  } On Library`}</span>
                </span>
              }
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="creditsLibraryTotal"
                    rules={[
                      {
                        required: true,
                        message: `
                    Please input ${
                      userData ? "remaining" : "total"
                    } credits for Library!
                    `,
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      min={0}
                      placeholder={userData ? "Remaining" : "Total"}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {userData && (
                <Form.Item wrapperCol={{ offset: 0, span: 15 }}>
                  {/* Create Label */}
                  <span>Total : {userData?.library_credits?.total}</span>
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item
              label={
                <span
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    style={{
                      color: "#ff4d4f",
                      fontSize: "0.875rem",
                    }}
                  >
                    *
                  </span>{" "}
                  <span style={{ fontSize: "0.875rem" }}>{`${
                    userData ? "Remaining Credits" : "Credits"
                  } On Demand`}</span>
                </span>
              }
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="onDemandTotal"
                    rules={[
                      {
                        required: true,
                        message: `Please input ${
                          userData ? "remaining" : "total"
                        } credits for On Demand!`,
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      min={0}
                      placeholder={userData ? "Remaining" : "Total"}
                    />
                  </Form.Item>
                  {userData && (
                    <Form.Item wrapperCol={{ offset: 0, span: 15 }}>
                      {/* Create Label */}
                      <span>Total : {userData?.ondemand_credits?.total}</span>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              label={
                <span
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    style={{
                      color: "#ff4d4f",
                      fontSize: "0.875rem",
                    }}
                  >
                    *
                  </span>{" "}
                  <span style={{ fontSize: "0.875rem" }}>
                    {userData ? "Remaining  myRa Credits " : "myRa Credits "}
                  </span>
                </span>
              }
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="myRaCredits"
                    rules={[
                      {
                        required: true,
                        message: `
                    Please input ${userData ? "remaining" : null}  myRa Credits!
                    `,
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      min={0}
                      placeholder={userData ? "Remaining" : "Total"}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {userData && (
                <Form.Item wrapperCol={{ offset: 0, span: 15 }}>
                  {/* Create Label */}
                  <span>Total : {userData?.myra_credits}</span>
                </Form.Item>
              )}
            </Form.Item>
          </>
        )}

        {role === "SALES_EXECUTIVE" && (
          <Form.Item
            label={<span style={{ fontSize: "0.875rem" }}>Hub List</span>}
            name="hubList"
            // rules={[
            //   { required: true, message: "Please select at least one hub!" },
            // ]}
          >
            <Select
              mode="multiple"
              placeholder="Select hubs"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >=
                0
              }
            >
              {hubListData?.data?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {isPartner !== true && (
          <Form.Item
            label={
              <span style={{ fontSize: "0.875rem" }}>
                User Access{" "}
                <Tooltip
                  title={
                    <>
                      <b>All Reports:</b> Client can access all reports in our
                      library and catalogue, excluding custom or NDA reports.
                      <br />
                      <b>Semi Access:</b> Client can access all reports plus
                      selected custom reports for that client only.
                      <br />
                      <b>Custom Reports:</b> Only selected custom reports
                      available for this client.
                    </>
                  }
                >
                  <InfoCircleOutlined style={{ marginLeft: 5 }} />
                </Tooltip>
              </span>
            }
            name="user_access"
            rules={[{ required: true, message: "Select user access type" }]}
          >
            <Select
              placeholder="Select User Access"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {UserAccess.map((item, index) => {
                return (
                  <Option value={item.value} key={index}>
                    <Tooltip title={item.tooltip} key={index}>
                      {item.label}
                    </Tooltip>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}

        {isPartner !== true && (
          // check box to give user myra access
          <Form.Item
            label={
              <span style={{ fontSize: "0.875rem" }}>
                Global myRA Access{" "}
                <Tooltip
                  title={
                    <>
                      Checking this will result in displaying "myRA AI" in the
                      Primary Tab of Synapse Platform for this client. They can
                      ask questions from all available reports, processed
                      through AI. This will result in a deduction of myRA
                      credits assigned to this client.
                    </>
                  }
                >
                  <InfoCircleOutlined style={{ marginLeft: 5 }} />
                </Tooltip>
              </span>
            }
            name="have_myra"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        )}

        <Form.Item
          label={
            <span style={{ fontSize: "0.875rem" }}>
              Allowed Domains{" "}
              <Tooltip
                title={
                  <>
                    You can list domains in a comma-separated format, which
                    includes everything after @ in the email. Allowed domains
                    ensure that only email IDs with these domains can create
                    alias accounts. For example:{" "}
                    <b>@yahoo.com, @gmail.com, @mordorintelligence.com</b> will
                    allow only emails with these domains.
                  </>
                }
              >
                <InfoCircleOutlined style={{ marginLeft: 5 }} />
              </Tooltip>
            </span>
          }
          name="allowedDomains"
          rules={[
            { required: true, message: "Add allowed domains for this user" },
          ]}
        >
          <Input placeholder="Enter comma-separated domains, e.g., @gmail.com, @yahoo.com" />
        </Form.Item>

        <Form.Item
          wrapperCol={{ offset: 8, span: 16 }}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button type="primary" htmlType="submit" style={{ width: "100px" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UserForm;
