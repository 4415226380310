import { useEffect, useState } from "react";
import { Spin } from "antd";
import UserListNew from "./UserList";
import { useGetNewUserQuery, useToggleUserMutation } from "../../../redux/api/createUserApi";
import NewCreateUser from "../CreateUser";
import { toast } from "react-toastify";
import "./UserList.css";
import UserListLayout from "./UserListLayout";

const UserList = () => {
  const [result, setResult] = useState([]);
  const [usersTableData, setUsersTableData] = useState([]);
  const [currentTab, setCurrentTab] = useState("ACTIVE-USER-LIST");
  const { data: userData, isLoading, isFetching } = useGetNewUserQuery();

  const [toggleUser, { data: toggleUserData, isSuccess: toggleUserSuccess, isError: toggleUserFailed }] = useToggleUserMutation();
  const [editUserId, setEditUserId] = useState(null);

  // Handle toggle user success/failure
  useEffect(() => {
    if (toggleUserSuccess) {
      toast.success(toggleUserData?.message);
    }
    if (toggleUserFailed) {
      toast.error(toggleUserData?.message ?? "Something went wrong");
    }
  }, [toggleUserSuccess, toggleUserFailed]);

  const navigateToEditUser = (userData) => {
    setEditUserId(userData);
  };

  const setToSearchScreen = () => {
    setEditUserId(null);
  };

  if (isLoading || isFetching) {
    return <Spin spinning={isLoading || isFetching} fullscreen />;
  }

  return (
    <UserListLayout
      editUserId={editUserId}
      result={result}
      setResult={setResult}
      data={userData?.data}
      setCurrentTab={setCurrentTab}
      currentTab={currentTab}
      setUsersTableData={setUsersTableData}
    >
      {editUserId ? (
        <NewCreateUser userData={editUserId} setToSearchScreen={setToSearchScreen} />
      ) : (
        <>
          <UserListNew result={usersTableData} toggleUser={toggleUser} navigateToEditUser={navigateToEditUser} />
        </>
      )}
    </UserListLayout>
  );
};

export default UserList;