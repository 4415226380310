import { useState, useEffect } from "react";
import { Layout, Typography, Tabs, Tooltip } from "antd";
import UsersListFilters from "./UsersListFilters";

const { Header, Content } = Layout;
const { Title } = Typography;
const { TabPane } = Tabs;

function UserListLayout({
  children,
  editUserId,
  result,
  setResult,
  data = [],
  setCurrentTab,
  currentTab = "ACTIVE-USER-LIST",
  setUsersTableData,
}) {
  const [activeTab, setActiveTab] = useState(currentTab);

  // Handle tab change
  const handleTabChange = (key) => {
    setActiveTab(key);
    setCurrentTab(key);
  };

  // Apply top-level filtering based on the active tab
  useEffect(() => {
    if (!data.length) return;

    let filteredData = [];
    switch (activeTab) {
      case "ACTIVE-USER-LIST":
        filteredData = data.filter((user) => user.active === true);
        break;
      case "INACTIVE-USER-LIST":
        filteredData = data.filter((user) => user.active === false);
        break;
      case "EXPIRED-USER-LIST":
        filteredData = data.filter(
          (user) =>
            user.access_type === "trial" &&
            new Date(user.trial_period_expiry) < new Date()
        );
        break;
      default:
        filteredData = [];
    }
    setResult(filteredData);
    setUsersTableData(filteredData);
  }, [activeTab, data, setResult, setUsersTableData]);

  if (editUserId) {
    return <>{children}</>;
  }

  return (
    <Layout className="user-list-layout">
      <Header
        className="header"
        style={{ background: "#fff", padding: "0 20px" }}
      >
        <Title level={4} style={{ margin: "16px 0" }}>
          User Management
        </Title>
      </Header>
      <Layout style={{ padding: "0 24px 24px" }}>
        <Tabs
          defaultActiveKey={activeTab}
          onChange={handleTabChange}
          style={{ marginBottom: 16 }}
        >
          <TabPane
            tab={
              <Tooltip title="Users which are set to active can access their accounts and platform freely.">
                Active Users
              </Tooltip>
            }
            key="ACTIVE-USER-LIST"
          />
          <TabPane
            tab={
              <Tooltip title="Marked as inactive, their account is unusable and will not be able to access platform at all.">
                Inactive Users
              </Tooltip>
            }
            key="INACTIVE-USER-LIST"
          />
          <TabPane
            tab={
              <Tooltip title="These users cannot access the platform since their trial period has ended.">
                Expired Trial Users
              </Tooltip>
            }
            key="EXPIRED-USER-LIST"
          />
        </Tabs>

        <Content>
          <UsersListFilters
            data={result}
            setUsersTableData={setUsersTableData}
          />
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}

export default UserListLayout;
