import { useState, useEffect, useMemo } from "react";
import { Select, Form, Row, Col, Button, Input, Switch } from "antd"; // Added Switch
import Fuse from "fuse.js";
import _ from "lodash";

function UsersListFilters({ data = [], setUsersTableData }) {
  const [filters, setFilters] = useState({
    companyName: null,
    relationshipManager: null,
    createdBy: null,
    searchText: "",
  });
  const [searchInputValue, setSearchInputValue] = useState("");
  const [showCustomUsers, setShowCustomUsers] = useState(false); // New state for toggle switch

  // Extract unique company names
  const uniqueCompanies = useMemo(() => {
    return [...new Set(data.map((item) => item.company_name))]
      .filter((company) => company)
      .sort();
  }, [data]);

  // Extract unique relationship managers
  const uniqueManagers = useMemo(() => {
    return [
      ...new Set(
        data
          .filter(
            (item) =>
              item.relationship_manager && item.relationship_manager.length > 0
          )
          .map((item) => item.relationship_manager[0].username)
      ),
    ].sort();
  }, [data]);

  // Extract unique created_by values
  const uniqueCreatedBy = useMemo(() => {
    return [...new Set(data.map((item) => item.created_by))]
      .filter((createdBy) => createdBy) // Filter out empty values
      .sort();
  }, [data]);

  // Initialize Fuse.js for searching
  const fuse = useMemo(() => {
    const fuseOptions = {
      keys: ["name", "email"],
      threshold: 0.3,
      includeScore: true,
    };
    return new Fuse(data, fuseOptions);
  }, [data]);

  // Debounced search function
  const debouncedSearch = useMemo(() => {
    return _.debounce((value) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        searchText: value,
      }));
    }, 1000);
  }, []);

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (value === searchInputValue) return;
    if (value === "") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        searchText: "",
      }));
      setUsersTableData(data); // Reset to the original tab-filtered data
    }
    setSearchInputValue(value);
    debouncedSearch(value);
  };

  // Apply filters to the data
  useEffect(() => {
    let filteredData = [...data];

    // Apply search filter
    if (filters.searchText) {
      const searchResults = fuse.search(filters.searchText);
      filteredData = searchResults.map((data) => data.item);
    }

    // Apply company filter
    if (filters.companyName) {
      filteredData = filteredData.filter(
        (item) => item.company_name === filters.companyName
      );
    }

    // Apply relationship manager filter
    if (filters.relationshipManager) {
      filteredData = filteredData.filter(
        (item) =>
          item.relationship_manager &&
          item.relationship_manager.length > 0 &&
          item.relationship_manager[0].username === filters.relationshipManager
      );
    }

    // Apply created_by filter
    if (filters.createdBy) {
      filteredData = filteredData.filter(
        (item) => item.created_by === filters.createdBy
      );
    }

    // Apply custom_user filter
    if (showCustomUsers) {
      filteredData = filteredData.filter((item) => item.custom_user === true);
    }

    setUsersTableData(filteredData);
  }, [filters, data, fuse, setUsersTableData, showCustomUsers]);

  // Reset all filters
  const resetFilters = () => {
    setFilters({
      companyName: null,
      relationshipManager: null,
      createdBy: null,
      searchText: "",
    });
    setSearchInputValue("");
    setShowCustomUsers(false); // Reset the toggle switch
    setUsersTableData(data); // Reset to the original tab-filtered data
  };

  return (
    <Form layout="vertical">
      <Row gutter={16} align="bottom">
        <Col xs={24}>
          <Form.Item label="Search by Name or Email">
            <Input.Search
              placeholder="Search users..."
              value={searchInputValue}
              onChange={handleSearchChange}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Form.Item label="Company">
            <Select
              allowClear
              showSearch
              placeholder="Filter by company"
              style={{ width: "100%" }}
              value={filters.companyName}
              onChange={(value) =>
                setFilters({ ...filters, companyName: value })
              }
              options={uniqueCompanies.map((company) => ({
                value: company,
                label: company || "N/A",
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Form.Item label="Relationship Manager">
            <Select
              allowClear
              showSearch
              placeholder="Filter by manager"
              style={{ width: "100%" }}
              value={filters.relationshipManager}
              onChange={(value) =>
                setFilters({ ...filters, relationshipManager: value })
              }
              options={uniqueManagers.map((manager) => ({
                value: manager,
                label: manager,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Form.Item label="Created By">
            <Select
              allowClear
              showSearch
              placeholder="Filter by creator"
              style={{ width: "100%" }}
              value={filters.createdBy}
              onChange={(value) => setFilters({ ...filters, createdBy: value })}
              options={uniqueCreatedBy.map((createdBy) => ({
                value: createdBy,
                label: createdBy,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item label="Show Custom Users">
            <Switch
              checked={showCustomUsers}
              onChange={(checked) => setShowCustomUsers(checked)}
              checkedChildren="Custom Users"
              unCheckedChildren="All Users"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={4}>
          <Form.Item>
            <Button onClick={resetFilters} style={{ marginRight: 8 }}>
              Reset Filters
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default UsersListFilters;
